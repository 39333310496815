body {
  background: rgb(42, 50, 158);
  color: #fff;
  font-family: monospace;
  font-size: 16px;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: transparent;
}

.App {
  background: linear-gradient(to bottom, #297af9, #333fb7);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.wrapper-temperature {
  margin-top: 60px;
  position: relative;
  transform: translateY(20px);
}

.forecasts {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  display: flex;
  flex-direction: column;
  margin-top: 0;

  &__scroll-panel {
    align-items: baseline;
    align-self: center;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    width: 342px;
  }

  .swiper-wrapper {
    align-items: flex-start;
    display: flex;
  }

  &__period {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;

    &.move-left,
    &.move-right {
      transition: transform .5s cubic-bezier(.86, 0, .07, 1);
    }

    &.move-left {
      transform: translateX(0);
    }

    &.move-right {
      transform: translateX(-350px);
    }
  }

  &--daily {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  #root {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .App {
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, .06);
    height: 667px;
    overflow: hidden;
    width: 375px;
  }
}
